import * as React from 'react';
import { graphql, type PageProps } from 'gatsby';
import { useReducer } from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';
import Layout from '../../components/layout';
import ShopSearchBanner from '../../components/shop-search-banner/shop-search-banner';
import PromotedShops from '../../components/promoted-shops/promoted-shops';
import ShopItemsGrid from '../../components/shops/shop-items-grid/shop-items-grid';
import { TrooperQueryParam } from '../../utils/query-params-const';
import { goToShopModalReducer, type GoToShopModalState } from '../../state/go-to-shop-modal/go-to-shop-modal-state';
import { GoToShopModalDispatchContext } from '../../contexts/go-to-shop-modal-context';
import GoToShopModal from '../../components/shared/modal/go-to-shop-modal/go-to-shop-modal';
import SEO from '../../components/shared/seo/seo';
import { shopSearchReducer } from '../../state/shop-search/shop-search-state';
import { ShopSearchContext } from '../../contexts/shop-search-context';
import AlternateLinks from '../../components/alternate-links/alternate-links';
import { TROOPER_PATHS } from '../../../constants';
import * as styles from './shops.module.scss';
import { getOppositeLanguage } from '../../utils/i18n-utils';

function ShopsPage({ location }: PageProps) {
  const { t, language } = useI18next();

  const [shopSearchState, shopSearchDispatch] = useReducer(shopSearchReducer, {
    categoryFilter: '',
    nameFilter: new URLSearchParams(location.search).get(TrooperQueryParam.ShopSearchTerm) ?? '',
    showPromotedShops: !new URLSearchParams(location.search).has(TrooperQueryParam.ShopSearchTerm),
  });

  const [goToShopModalState, goToShopModalDispatch] = useReducer(goToShopModalReducer, {
    isOpened: false,
  } as GoToShopModalState);

  const oppositeLanguage = getOppositeLanguage(language);
  const otherLanguagePath = `/${oppositeLanguage}/${TROOPER_PATHS[oppositeLanguage].shops}`;

  return (
    <Layout otherLanguagePath={otherLanguagePath}>
      <div className={styles.container}>
        <h2 className={styles.title}>{t('Troopershops_Title')}</h2>
        <div
          className={styles.description}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={
                    { __html: t('Troopershops_Description', { interpolation: { escapeValue: false } }) }
                }
        />
      </div>

      <GoToShopModalDispatchContext.Provider value={goToShopModalDispatch}>
        {/* eslint-disable-next-line react/jsx-no-constructed-context-values */}
        <ShopSearchContext.Provider value={{ state: shopSearchState, dispatch: shopSearchDispatch }}>
          <ShopSearchBanner associationName={null} />
          <section>
            <div className={styles.container}>
              {shopSearchState.showPromotedShops ? <PromotedShops /> : null}
              <ShopItemsGrid />
            </div>
          </section>
        </ShopSearchContext.Provider>
        {goToShopModalState.isOpened ? (
          <GoToShopModal
            shop={goToShopModalState.shop}
            isOpen={goToShopModalState.isOpened}
          />
        ) : null}
      </GoToShopModalDispatchContext.Provider>
    </Layout>
  );
}

export function Head({ data, location }: PageProps<Queries.AssociationSearchResultPageQuery>) {
  // https://github.com/gatsbyjs/gatsby/issues/36458

  // @ts-expect-error
  const dataLanguage = data.locales.edges.find(
    (e) => e.node.ns === 'Metadescriptions_Troopershops_Og',
  ).node.data;

  // @ts-expect-error
  const t = JSON.parse(dataLanguage);

  return (
    <SEO
      image="https://trooperv2.blob.core.windows.net/staticassets/og_troopershops.jpg"
      title={t.Metadescriptions_Troopershops_Og_Title}
      description={t.Metadescriptions_Troopershops_Og_Description}
      ogTitle={t.Metadescriptions_Troopershops_Og_Title}
      ogDescription={t.Metadescriptions_Troopershops_Og_Description}
      pathname={location.pathname}
    >
      <AlternateLinks nlPath={TROOPER_PATHS.nl.shops} frPath={TROOPER_PATHS.fr.shops} />
    </SEO>
  );
}

export const query = graphql`
    query ShopsPage($language: String!) {
      locales: allLocale(filter: {ns: {in: [
        "Metadescriptions_Troopershops_Og",
        "Troopershops",
        "Association_SearchPlaceholders",
        "Search",
        "Association",
        "Association_ShopPopup",
        "Association_ShopCookiePopup",
      ]}, language: {eq: $language}}) {
        edges {
            node {
                ns
                data
                language
            }
        }
    }
}`;
export default ShopsPage;
